import React, {useEffect, useState} from 'react';
import gsap from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'

import { ReactComponent as AboutUsLink } from '../assets/aboutUs.svg';
import { ReactComponent as MenuClose } from '../assets/menuClose.svg';
import { ReactComponent as MenuContainerTop } from '../assets/menuContainerSVG.svg';
import { viewportCoordinate } from 'three/examples/jsm/nodes/Nodes.js';

const MenuLinks = ({onClose}) => {
    gsap.registerPlugin(CSSRulePlugin);

    useEffect(() => {
        const menuBackgroundBlink = CSSRulePlugin.getRule("#menu-container:after")

        gsap.to("#menu-container-svg", {attr: {d: "M0.5 3.73241e-05C0.5 3.73241e-05 532.5 -8.21224e-05 944.5 0.000106346C1356.5 0.000294815 1920.5 3.73241e-05 1920.5 3.73241e-05V70C1920.5 70 1386 300 929.5 300C473 300 0.5 70 0.5 70V3.73241e-05Z"}, duration: 0.25})
        gsap.to("#menu-container-svg-section", {attr:{viewBox:"0 0 1920 300"}, height: 300, width: 3000, duration: 0.25});
        gsap.to("#menu-container-svg", {attr: {d: "M0.5 3.73241e-05C0.5 3.73241e-05 532.5 -8.21224e-05 944.5 0.000106346C1356.5 0.000294815 1920.5 3.73241e-05 1920.5 3.73241e-05V70C1920.5 70 1401 70 944.5 70C488 70 0.5 70 0.5 70V3.73241e-05Z"}, duration: 0.25, delay: 0.25})
        gsap.to("#menu-container-svg-section", {attr:{viewBox:"0 0 1920 70"}, height: 70, width: 3000, duration: 0.25, delay: 0.25});
        gsap.set("#menu-container", {y: "-100%"});
        gsap.to("#menu-container", {y: 0, duration: 0.5});
    }, [onClose])

    const hideMenu = () => {
        gsap.to("#menu-container", {
            y: "100%",
            duration: 0.3,
            onComplete: (self) => {
                onClose(false);
            }
        });
    }

  return (
    <div className='container-fluid m-0 p-0 d-flex flex-column align-items-center justify-content-between overflow-hidden position-absolute z-3' id='menu-container' style={{height: "100vh"}}>
        <div className='col-12 bg-cyan d-flex justify-content-center'>
            <div className='container p-2 d-flex justify-content-end'>
                <a className='cursor-pointer' onClick={(e) => {hideMenu(); document.body.style.overflow = "visible";}}><MenuClose /></a>
            </div>
        </div>
        <div className='col-12 bg-cyan d-flex flex-column flex-grow-1 justify-content-center align-items-center'>
            <a href='/about-servin/' className='font-shanti text-white menu-link-shadow title-text my-3' id='menu-text-2'>About Servin</a>
            <a href='/about-us/' className='font-shanti text-white menu-link-shadow title-text my-3' id='menu-text-1'>About us</a>
            <a href='/investor-relations/' className='font-shanti text-white menu-link-shadow title-text my-3' id='menu-text-3'>Investor Relations</a>
            <a href='/contacts/' className='font-shanti text-white menu-link-shadow title-text my-3' id='menu-text-4'>Contacts</a>
        </div>
        <MenuContainerTop className='col-12' />
    </div>
  )
}

export default MenuLinks