import React, {useEffect} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Header from '../components/Header';

import '../static/aboutServinPage.css';

import AboutServinHeroImage from '../assets/img/about-servin-hero-section-image.png';
import FirstSectionImage from '../assets/img/about-servin-page/first-section-image.png';
import SecondSectionImage from '../assets/img/about-servin-page/second-section-image.png';
import ThirdSectionImage from '../assets/img/about-servin-page/third-section-image.png';

import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg';

const AboutServinPage = () => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {

        let gsapResponsive = gsap.matchMedia();

        gsapResponsive.add("(min-width: 768px)", () => {
            gsap.fromTo("#first-section-image", {y: 150}, {
                y: 0,
                scrollTrigger: {
                    trigger: "#first-section-container",
                    start: "top bottom",
                    end: "bottom bottom",
                    scrub: true
                }
            });
    
            gsap.fromTo("#second-section-image", {y: 150}, {
                y: 0,
                scrollTrigger: {
                    trigger: "#second-section-container",
                    start: "top bottom",
                    end: "bottom bottom",
                    scrub: true
                }
            });
    
            gsap.fromTo("#third-section-image", {y: 150}, {
                y: 0,
                scrollTrigger: {
                    trigger: "#third-section-container",
                    start: "top bottom",
                    end: "bottom bottom",
                    scrub: true
                }
            });
        });

        gsapResponsive.add("(max-width: 767px)", () => {
            gsap.fromTo("#first-section-image", {y: 150}, {
                y: 0,
                scrollTrigger: {
                    trigger: "#first-section-container",
                    start: "top bottom",
                    end: "bottom 70%",
                    scrub: true
                }
            });
    
            gsap.fromTo("#second-section-image", {y: 150}, {
                y: 0,
                scrollTrigger: {
                    trigger: "#second-section-container",
                    start: "top bottom",
                    end: "bottom 70%",
                    scrub: true
                }
            });
    
            gsap.fromTo("#third-section-image", {y: 150}, {
                y: 0,
                scrollTrigger: {
                    trigger: "#third-section-container",
                    start: "top bottom",
                    end: "bottom 70%",
                    scrub: true
                }
            });
        });

    }, [])

  return (
    <div>
        <Header />

        <section className='container-fluid m-0 p-0 d-flex flex-column-reverse flex-lg-row about-servin-hero-section-container' style={{height: "100vh"}}>
            <div className='col-lg-6 h-100 bg-cyan d-flex align-items-end box'>
                <img src={AboutServinHeroImage} className='img-fluid' />
            </div>

            <div className='col-lg-6 d-flex justify-content-center move-from-header'>
                <div className='col-10 d-flex flex-column justify-content-between pb-5'>
                    <div className='d-flex flex-column align-items-center row-gap-3'>
                        <h1 className='title-text text-center dark-text font-shanti'>Technology, usefulness and convenience</h1>
                        <p className='col-12 col-xl-12 text-center responsive-text-1'> Servin is a mobile platform where every user can find, agree on, arrange and pay for the service they need. The mobile application will allow end customers and service providers to find each other easily and quickly, view reviews, discuss the problem, set a time and place, and agree on a price - all transparently and in a few clicks in one place. Servin will help people quickly find solutions to their problems, and for service providers, it will open up unlimited job opportunities.</p>
                    </div>

                    <div className='d-flex justify-content-center justify-content-xxl-end'>
                        <a className='dark-text font-anton mt-5 fs-2 bg-cyan px-4 py-3 rounded-3'>About us <ArrowRight className='ms-2' /></a>
                    </div>
                </div>
            </div>
        </section>

        <section className='container-fluid d-flex flex-column align-items-center row-gap-5 move-from-header' style={{minHeight: "100vh"}}>
            <div className='col-11 col-md-10 my-5 d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center align-items-lg-stretch row-gap-4'>
                <div className='col-12 col-md-9 col-lg-6 d-flex flex-column justify-content-end'>
                    <h1 className='text-start dark-text font-anton my-4'>Convenience and easy to use</h1>
                    <p className='text-start responsive-text-1-2'>Our platform is designed with simplicity and user-friendliness in mind, making it easy for both clients and service providers to navigate and use. For clients, finding and booking a service takes just a few clicks, with clear options and straightforward communication tools. For service providers, managing orders, setting schedules, and tracking earnings is hassle-free. Whether you're seeking help or offering your expertise, Servin makes the process smooth, intuitive, and efficient for everyone involved.</p>
                </div>

                <div className='col-12 col-md-9 col-lg-5 position-relative d-flex justify-content-center' id='first-section-container'>
                    <div className='col-12 bg-radial-cyan-dark position-absolute bottom-0 z-0 rounded-4' style={{height: "80%"}}></div>
                    <img src={FirstSectionImage} className='img-fluid z-1' id='first-section-image' />
                </div>
            </div>

            <div className='col-11 col-md-10 my-5 d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-lg-stretch row-gap-4'>
                <div className='h-100 col-12 col-md-9 col-lg-5 position-relative d-flex justify-content-center' id='second-section-container'>
                    <div className='col-12 bg-radial-cyan-dark position-absolute bottom-0 z-0 rounded-4' style={{height: "80%"}}></div>
                    <img src={SecondSectionImage} className='img-fluid z-1' id='second-section-image' />
                </div>

                <div className='col-12 col-md-9 col-lg-6 d-flex flex-column justify-content-end'>
                    <h1 className='text-start dark-text font-anton my-4'>Transparency and Digital Contracts</h1>
                    <p className='text-start responsive-text-1-2'>Servin prioritizes transparency in all transactions. Every agreement between a client and a service provider is documented through digital contracts, ensuring clear terms and expectations. This approach minimizes misunderstandings and protects both parties, offering peace of mind and a secure, reliable platform for all interactions. With Servin, you can trust that every detail is clear, fair, and easy to manage.</p>
                </div>
            </div>

            <div className='col-11 col-md-10 my-5 d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center align-items-lg-stretch row-gap-4'>
                <div className='col-12 col-md-9 col-lg-6 d-flex flex-column justify-content-end'>
                    <h1 className='text-start dark-text font-anton my-4'>New approach in relationship </h1>
                    <p className='text-start text-start responsive-text-1-2'>Servin revolutionizes the way clients and service providers interact by fostering a more collaborative and respectful relationship. Through our platform, both parties have direct communication channels, allowing for better understanding and personalized service. This approach encourages mutual respect, trust, and long-term relationships, transforming the traditional transactional model into a more engaging and rewarding experience for everyone involved.</p>
                </div>

                <div className='col-12 col-md-9 col-lg-5 position-relative d-flex justify-content-center' id='third-section-container'>
                    <div className='col-12 bg-radial-cyan-dark position-absolute bottom-0 z-0 rounded-4' style={{height: "80%"}}></div>
                    <img src={ThirdSectionImage} className='img-fluid z-1' id='third-section-image' />
                </div>
            </div>
        </section>
    </div>
  )
}

export default AboutServinPage