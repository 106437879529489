import React, {useState} from 'react';
import gsap from 'gsap';

import { ReactComponent as NumberOne } from '../assets/svg-numbers/number-one.svg';
import { ReactComponent as NumberTwo } from '../assets/svg-numbers/number-two.svg';
import { ReactComponent as NumberThree } from '../assets/svg-numbers/number-three.svg';
import { ReactComponent as NumberFour } from '../assets/svg-numbers/number-four.svg';

import { ReactComponent as ClientSectionOne } from '../assets/features-svg/client-feature-section-one.svg';
import { ReactComponent as ClientSectionTwo } from '../assets/features-svg/client-feature-section-two.svg';
import { ReactComponent as ClientSectionThree } from '../assets/features-svg/client-feature-section-three.svg';

import '../static/homePageFeatures.css';

const HomePageFeaturesSection = () => {
    const [featuresOption, setFeaturesOption] = useState("client");

    const changeToClientSection = async () => {
        gsap.to("#worker-section", {
            translateX: 100,
            opacity: 0,
            duration: 0.3,
        });

        await setFeaturesOption("client");

        await gsap.to("#client-section", {
            translateX: 0,
            opacity: 1,
            duration: 0.3,
            delay: 0.3
        });
    }

    const changeToWorkerSection = async () => {
        gsap.to("#client-section", {
            translateX: -100,
            opacity: 0,
            duration: 0.3,
        });

        await setFeaturesOption("worker");

        await gsap.to("#worker-section", {
            translateX: 0,
            opacity: 1,
            duration: 0.3,
            delay: 0.3
        });
    }

  return (
    <div className='col-12'>
        <div className='col-12 position-relative' id='features-section-section' style={{height: "100vh"}}>
            <div className='col-12 col-lg-8 mb-5 hero-section-position-absolute-after-md' id='features-section-container' style={{height: "auto"}}>
                <div className='col-12 col-md-auto d-inline-block background-blured'>
                    <h1 className='text-center text-md-start w-100 fw-bold title-text mb-2 font-anton d-inline-block' id='features-section-title'>Why use us?</h1>
                    <div className='d-flex flex-row justify-content-center align-items-center' id='features-section-buttons'>
                        <a className={'mx-3 px-4 py-1 text-secondary link-underline link-underline-opacity-0 border border-secondary border-2 rounded-pill cursor-pointer ' + (featuresOption == "client" ? "active-feature-section" : '')} onClick={(e) => changeToClientSection()}>Client</a>
                        <a className={'mx-3 px-4 py-1 text-secondary link-underline link-underline-opacity-0 border border-secondary border-2 rounded-pill cursor-pointer ' + (featuresOption == "worker" ? "active-feature-section" : '')} onClick={(e) => changeToWorkerSection()}>Performer</a>
                    </div>
                </div>

                {featuresOption === "worker" ? 
                    <div className='col-xl-8 mt-5 background-blured' id='worker-section'>
                        <div className='d-flex flex-row my-1 position-relative' id='features-section-first-feature'>
                            <NumberOne />
                            <div className='ms-2'>
                                <h4 className='fw-medium'>Lack of opportunities to find customers</h4>
                                <p className='font-inter text-secondary fw-light fst-italic feature-description'>Finding consistent work can be a challenge. Servin connects you directly with clients in need of your services, ensuring a steady stream of opportunities without the hassle of searching for them.</p>
                            </div>
                        </div>

                        <div className='d-flex flex-row my-1' id='features-section-second-feature'>
                            <NumberTwo />
                            <div className='ms-2'>
                                <h4 className='fw-medium'>Clear terms and conditions</h4>
                                <p className='font-inter text-secondary fw-light fst-italic feature-description'>With Servin, you work with clear, transparent terms. The platform provides a structured environment where both you and the client know what to expect, minimizing misunderstandings and disputes.</p>
                            </div>
                        </div>

                        <div className='d-flex flex-row my-1' id='features-section-third-feature'>
                            <NumberThree />
                            <div className='ms-2'>
                                <h4 className='fw-medium'>Flexible work schedule and time management</h4>
                                <p className='font-inter text-secondary fw-light fst-italic feature-description'>Servin allows you to set your own schedule, giving you the freedom to work when it suits you best. You can manage your time effectively, balancing work with personal commitments.</p>
                            </div>
                        </div>

                        <div className='d-flex flex-row my-1' id='features-section-fourth-feature'>
                            <NumberFour />
                            <div className='ms-2'>
                                <h4 className='fw-medium'>Own pricing policy</h4>
                                <p className='font-inter text-secondary fw-light fst-italic feature-description'>You have full control over your rates. Set prices that reflect your skills and the market demand, ensuring you're compensated fairly for your work.</p>
                            </div>
                        </div>
                    </div>
                :
                    <div className='col-xl-8 mt-5 background-blured' id='client-section'>
                        <div className='d-flex flex-row my-1 position-relative' id='features-section-first-feature'>
                            <NumberOne />
                            <div className='ms-2'>
                                <h4 className='fw-medium'>You have no time or skills to fix something in the household</h4>
                                <p className='font-inter text-secondary fw-light fst-italic feature-description'>Finding a reliable service provider can be challenging and time-consuming. With Servin, you can quickly find skilled professionals to handle household tasks, saving you time and effort.</p>
                            </div>
                        </div>

                        <div className='d-flex flex-row my-1' id='features-section-second-feature'>
                            <NumberTwo />
                            <div className='ms-2'>
                                <h4 className='fw-medium'>You have an urgent need for a quick resolution.</h4>
                                <p className='font-inter text-secondary fw-light fst-italic feature-description'>When a problem arises that requires immediate attention, you don't have time to search for the right help. Servin connects you with nearby experts who can be at your location quickly, ensuring fast and efficient service.</p>
                            </div>
                        </div>

                        <div className='d-flex flex-row my-1' id='features-section-third-feature'>
                            <NumberThree />
                            <div className='ms-2'>
                                <h4 className='fw-medium'>You are looking for the best service for the best price</h4>
                                <p className='font-inter text-secondary fw-light fst-italic feature-description'>Servin allows you to compare professionals based on ratings, reviews, and prices, ensuring you get the best service at a price that fits your budget.</p>
                            </div>
                        </div>

                        <div className='d-flex flex-row my-1' id='features-section-fourth-feature'>
                            <NumberFour />
                            <div className='ms-2'>
                                <h4 className='fw-medium'>You value convenience and transparency</h4>
                                <p className='font-inter text-secondary fw-light fst-italic feature-description'>Servin offers a seamless experience where you can easily book, communicate with, and pay for services all within the app. You’ll always know what to expect, with no hidden fees or surprises, making the entire process straightforward and stress-free.</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

        {featuresOption === "worker" ?
            <section className='container-fluid min-h-before-md-auto d-flex flex-column justify-content-evenly align-items-center mt-5' style={{height: '200vh'}}>
                <div className='col-md-11 h-25 mb-before-md-5 d-flex flex-column flex-lg-row justify-content-between row-gap-3'>
                    <div className='col-lg-6 bg-cyan rounded-3 h-100' style={{minHeight: "35vh"}}>
                        <ClientSectionOne />     
                    </div>
                    <div className='col-lg-5'>
                        <p className='text-justify-spread-lg responsive-text-1-2'>Imagine that a client has a broken tap and needs a plumber urgently. In a normal situation, finding a master can be a real ordeal: calling acquaintances, searching for adverts on the internet, long negotiations and agreeing on a time. All this requires considerable effort and time, especially when the problem requires an urgent solution.</p>
                    </div>
                </div>

                <div className='col-md-11 h-25 mb-before-md-5 d-flex flex-column flex-lg-row-reverse justify-content-between row-gap-3'>
                    <div className='col-lg-6 bg-cyan rounded-3 h-100' style={{minHeight: "35vh"}}>
                        <ClientSectionTwo />     
                    </div>
                    <div className='col-lg-5'>
                        <p className='text-justify-spread-lg responsive-text-1-2'>With Servin app this process is reduced to a few simple steps. The customer opens the app and immediately sees a list of available plumbers in their area, with profiles, ratings and reviews. There is no need to spend time on negotiations - the client simply chooses a convenient time and calls the master at home. <br /> <br /> The performer receives notifications about new orders and can respond immediately. He sees all the necessary information, which allows him to prepare and organise his time as efficiently as possible.</p>
                    </div>
                </div>

                <div className='col-md-11 h-25 mb-before-md-5 d-flex flex-column flex-lg-row justify-content-between row-gap-3'>
                    <div className='col-lg-6 bg-cyan rounded-3 h-100' style={{minHeight: "35vh"}}>
                        <ClientSectionThree />   
                    </div>
                    <div className='col-lg-5'>
                        <p className='text-justify-spread-lg responsive-text-1-2'>The platform gives the handyman a stable flow of orders and flexible working hours, which saves his time. <br /> <br /> In this way, our application saves time and efforts of the client, making the process of ordering services convenient, fast and safe, which fully meets the requirements of the modern world.</p>
                    </div>
                </div>
            </section>
        :
            <section className='container-fluid min-h-before-md-auto d-flex flex-column justify-content-evenly align-items-center mt-5' style={{height: '200vh'}}>
                <div className='col-md-11 h-25 mb-before-md-5 d-flex flex-column flex-lg-row-reverse justify-content-between row-gap-3'>
                    <div className='col-lg-6 bg-cyan rounded-3 h-100' style={{minHeight: "35vh"}}>
                        <ClientSectionOne />    
                    </div>
                    <div className='col-lg-5'>
                        <p className='text-justify-spread-lg responsive-text-1-2'>Imagine that a client has a broken tap and needs a plumber urgently. In a normal situation, finding a master can be a real ordeal: calling acquaintances, searching for adverts on the internet, long negotiations and agreeing on a time. All this requires considerable effort and time, especially when the problem requires an urgent solution.</p>
                    </div>
                </div>

                <div className='col-md-11 h-25 mb-before-md-5 d-flex flex-column flex-lg-row justify-content-between row-gap-3'>
                    <div className='col-lg-6 bg-cyan rounded-3 h-100' style={{minHeight: "35vh"}}>
                        <ClientSectionTwo />   
                    </div>
                    <div className='col-lg-5'>
                        <p className='text-justify-spread-lg responsive-text-1-2'>With Servin app this process is reduced to a few simple steps. The customer opens the app and immediately sees a list of available plumbers in their area, with profiles, ratings and reviews. There is no need to spend time on negotiations - the client simply chooses a convenient time and calls the master at home. <br /> <br /> The performer receives notifications about new orders and can respond immediately. He sees all the necessary information, which allows him to prepare and organise his time as efficiently as possible.</p>
                    </div>
                </div>

                <div className='col-md-11 h-25 mb-before-md-5 d-flex flex-column flex-lg-row-reverse justify-content-between row-gap-3'>
                    <div className='col-lg-6 bg-cyan rounded-3 h-100' style={{minHeight: "35vh"}}>
                        <ClientSectionThree />   
                    </div>
                    <div className='col-lg-5'>
                        <p className='text-justify-spread-lg responsive-text-1-2'>The platform gives the handyman a stable flow of orders and flexible working hours, which saves his time. <br /> <br /> In this way, our application saves time and efforts of the client, making the process of ordering services convenient, fast and safe, which fully meets the requirements of the modern world.</p>
                    </div>
                </div>
            </section>
        }
    </div>
  )
}

export default HomePageFeaturesSection