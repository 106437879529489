import React, {useEffect} from 'react';
import gsap from 'gsap';

import '../static/aboutUsPage.css';

import Header from '../components/Header';
import Footer from '../components/Footer';

import NutsuImage from '../assets/img/about-us-page/our-team/nutsu.jpg';
import YonashImage from '../assets/img/about-us-page/our-team/yonash.jpg';
import VovaImage from '../assets/img/about-us-page/our-team/vova.jpg';
import VovaHayek from '../assets/img/about-us-page/our-team/vovahayek.png';
import NutsuVlad from '../assets/img/about-us-page/our-team/nutsuvlad.png';
import YonashStan from '../assets/img/about-us-page/our-team/yonashstan.png';

const AboutUsPage = () => {

    useEffect(() => {
        gsap.set(".about-us-hero-square-images", {scale: 0.5})
        gsap.to(".about-us-hero-square-images", {scale: 1, duration: 0.7, ease: "back.out(1.7)"})
    })

  return (
    <div>
        <Header />
        
        <section className='container-fluid bg-white mx-0 mb-5 move-from-header p-0 d-flex justify-content-center align-items-end about-us-hero-section-cyan-block' style={{minHeight: "100vh"}}>
            <div className='col-11 mb-5 py-5 d-flex flex-wrap flex-lg-row justify-content-evenly align-items-center row-gap-4'>
                <div className='col-5 col-md-5 col-lg-3 d-flex justify-content-center align-items-center'><img src={YonashStan} className='col-sm-8 col-md-5 col-lg-11 img-fluid rounded-4' /></div>
                <div className='col-5 col-md-5 col-lg-3 d-flex justify-content-center align-items-center'><img src={NutsuVlad} className='col-sm-8 col-md-5 col-lg-10 mt-5 img-fluid rounded-4' /></div>
                <div className='col-5 col-md-5 col-lg-3 d-flex justify-content-center align-items-center'><img src={VovaHayek} className='col-sm-8 col-md-5 col-lg-11 img-fluid rounded-4' /></div>
            
                <h1 className='text-center text-spacing-3 font-inter-light fs-5'>Servin strives to be the leading platform that makes it easy to find and hire trusted professionals for domestic and professional tasks. We believe in convenience, transparency and the highest quality of service for every customer.</h1>
            </div>
        </section>

        {/* <section className='container-fluid d-flex justify-content-center mb-5 mt-4'>
            <div className='col-11 col-lg-11'>
                <h1 className='text-center text-spacing-3 font-inter fs-5'>Servin strives to be the leading platform that makes it easy to find and hire trusted professionals for domestic and professional tasks. We believe in convenience, transparency and the highest quality of service for every customer.</h1>
            </div>
        </section> */}

        <section className='container-fluid m-0 px-0 py-1 d-flex flex-column align-items-center row-gap-5'>
            <div className='col-11 col-lg-10 d-flex flex-wrap flex-lg-row justify-content-center justify-content-md-between align-items-start align-items-lg-stretch row-gap-2 about-us-page-about-each-member-section-row-gap'>
                <div className='about-us-name-title-text col-12'>
                    <h1 className='title-text font-anton m-0 text-center text-md-start'>NUTSU VLAD</h1>
                    <p className='fs-5 fw-semibold font-inter text-center text-md-start'>CEO, Creator of the ServIn</p>
                </div>
                
                <div className='col-10 col-sm-6 col-md-4 d-flex justify-content-center align-items-center'>
                    <img src={NutsuImage} className='img-fluid border-rounded-10' />
                </div>

                <div className='col-11 col-md-7'>
                    <div className='about-us-name-title-text-lg'>
                        <h1 className='title-text font-anton m-0 text-start'>NUTSU VLAD</h1>
                        <p className='fs-5 fw-semibold font-inter text-start'>CEO, Creator of the ServIn</p>
                    </div>

                    <p className='about-me-responsive-text font-inter text-center text-md-start'>CEO and project inspirer, a native of western Ukraine, ethnic Romanian. He was educated at the Timiryazev Academy, where he studied Industrial and Civil Engineering. After completing his studies, Nutsu moved to the Czech Republic and started his career in the construction industry. He soon became an entrepreneur, continuing to develop his skills in construction and design. Nutsu also worked as a designer at Studio MA s.r.o. and later co-founded Archisoul S.R.O. Nutsu has good communication and contractual skills, as well as his strengths are visionary and practicality. In the team he is responsible for the platform logic, visionary as well as strategic vision and business processes.</p>
                </div>
            </div>

            <div className='col-11 col-lg-10 d-flex flex-wrap flex-lg-row justify-content-center justify-content-md-between align-items-start align-items-lg-stretch row-gap-2 about-us-page-about-each-member-section-row-gap'>
                <div className='about-us-name-title-text col-12'>
                        <h1 className='title-text font-anton m-0 text-center text-md-start'>YONASH STAN</h1>
                        <p className='fs-5 fw-semibold font-inter text-center text-md-start'>Software Developer</p>
                    </div>
                
                <div className='col-lg-7 about-us-name-title-text-lg'>
                    <div>
                        <h1 className='title-text font-anton m-0 text-center text-lg-start'>YONASH STAN</h1>
                        <p className='fs-5 fw-semibold font-inter text-center text-lg-start'>Software Developer</p>
                    </div>

                    <p className='about-me-responsive-text font-inter'>Native of western Ukraine, ethnic Romanian and Ukrainian. Enrolled at the Romanian Faculty of Technical University in Cluj-Napoca, where he successfully graduated with honors. He worked on application projects and freelancing, which allowed him to gain significant experience in software development. In addition, Yonash has experience in various fields, including working as a translator and in sales, which has helped him develop his communication and people skills.  Yonash is responsible for the mobile app development, ideation and design for our startup and is an important contributor to the technical and implementation of the project.</p>
                </div>

                <div className='col-10 col-sm-6 col-md-4 d-flex justify-content-center align-items-center'>
                    <img src={YonashImage} className='img-fluid border-rounded-10' />
                </div>

                <div className='col-11 col-md-7 about-us-name-title-text'>
                    <p className='about-me-responsive-text font-inter text-center text-md-start'>Native of western Ukraine, ethnic Romanian and Ukrainian. Enrolled at the Romanian Faculty of Technical University in Cluj-Napoca, where he successfully graduated with honors. He worked on application projects and freelancing, which allowed him to gain significant experience in software development. In addition, Yonash has experience in various fields, including working as a translator and in sales, which has helped him develop his communication and people skills.  Yonash is responsible for the mobile app development, ideation and design for our startup and is an important contributor to the technical and implementation of the project.</p>
                </div>
            </div>

            <div className='col-11 col-lg-10 d-flex flex-wrap flex-lg-row justify-content-center justify-content-md-between align-items-center align-items-lg-stretch row-gap-4 about-us-page-about-each-member-section-row-gap'>
                <div className='about-us-name-title-text col-12'>
                    <h1 className='title-text font-anton m-0 text-center text-md-start'>Volodymyr Hayek</h1>
                    <p className='fs-5 fw-semibold font-inter text-center text-md-start'>Web Designer, Full-Stack Developer</p>
                </div>
                
                <div className='col-10 col-sm-6 col-md-4 d-flex justify-content-center align-items-center'>
                    <img src={VovaImage} className='img-fluid border-rounded-10' />
                </div>

                <div className='col-11 col-md-7'>
                    <div className='about-us-name-title-text-lg'>
                        <h1 className='title-text font-anton m-0 text-center text-lg-start'>Volodymyr Hayek</h1>
                        <p className='fs-5 fw-semibold font-inter text-center text-lg-start'>Web Designer, Full-Stack Developer</p>
                    </div>

                    <p className='about-me-responsive-text font-inter text-center text-md-start'>A 19-year-old Full-Stack developer from Rakhiv, Ukraine. Since childhood, he has been trying his hand at various things: from video processing to creating 3D models. At the age of 12, Volodymyr discovered programming, starting with Unity, but temporarily gave it up due to its complexity. In 2019, he returned to programming, focusing on web development, and realized that this was his calling. Since 2021, he has been studying at the Faculty of Cyber Security at Lviv Polytechnic. In 2023 founded VH Development, which has already created four innovative websites. Volodymyr manages the development and administration of web applications, significantly improving the quality of the product.</p>
                </div>
            </div>
        </section>
        
        <div className='about-us-footer-section-gradient'></div>
        <Footer />
    </div>
  )
}

export default AboutUsPage