import React, {useEffect} from 'react';
import * as THREE from 'three';
import gsap from 'gsap';

import '../static/loadingScreen.css';

import { ReactComponent as LoadingText } from '../assets/loadingText.svg';
import { ReactComponent as LoadingTextPhone } from '../assets/loadingTextPhone.svg';

const LoadingScreen = () => {

    useEffect(() => {
      if (window.location.pathname == '/' && window.innerWidth > 700) {
        THREE.DefaultLoadingManager.onStart = function ( url, itemsLoaded, itemsTotal ) {
            console.log('');
          };
          
          THREE.DefaultLoadingManager.onLoad = function ( ) {
            gsap.to('#loading-screen-container', {y: "-100%", duration: 0.3, delay: 0.5});
          };
          
          THREE.DefaultLoadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
            gsap.to('#clip-container', {
                width: `${(itemsLoaded/itemsTotal)*100}%`,
                duration: 0.5
            })
          };
          
          THREE.DefaultLoadingManager.onError = function ( url ) {
            console.log( 'There was an error loading ' + url );
          };
      } else {
        gsap.to('#clip-container', {
          width: `100%`,
          duration: 0.5,
          onComplete: (() => {
            gsap.to('#loading-screen-container', {y: "-100%", duration: 0.3, delay: 0.5});
          })
      })
      }
    }, [])

  return (
    <div className='container-fluid bg-cyan position-fixed top-0 start-0 d-flex justify-content-center align-items-center' id='loading-screen-container' style={{height: "100vh", zIndex: "4"}}>
        <div>
          {window.innerWidth >= 500 ? <LoadingText /> : <LoadingTextPhone />}
        </div>
    </div>
  )
}

export default LoadingScreen