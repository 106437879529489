import React, {useEffect, useState} from 'react';

import {HeaderWhite} from '../components/Header';

import '../static/contactsPage.css';

const ContactsPage = () => {
  const [chosenForm, setChosenForm] = useState(1);

  return (
    <div>
      <HeaderWhite />

      <div className='container-fluid d-flex flex-column justify-content-center align-items-center background-gradient' style={{height: '100vh'}}>
          <div className='col-md-11 col-lg-10 col-xl-8 h-75 d-flex flex-column justify-content-evenly'>
            <div className='col-12 d-flex justify-content-between'>
              <a className={`bg-white font-inter-semibold text-dark fs-4 fw-bold px-4 py-2 rounded-3 button-shadow cursor-pointer ` + (chosenForm == 1 ? 'choosen-form-section-button' : null)} onClick={(e) => setChosenForm(1)}>Support</a>
              <a className={`bg-white font-inter-semibold text-dark fs-4 fw-bold px-4 py-2 rounded-3 button-shadow cursor-pointer ` + (chosenForm == 2 ? 'choosen-form-section-button' : null)} onClick={(e) => setChosenForm(2)}>Cooperation</a>
              <a className={`bg-white font-inter-semibold text-dark fs-4 fw-bold px-4 py-2 rounded-3 button-shadow cursor-pointer ` + (chosenForm == 3 ? 'choosen-form-section-button' : null)} onClick={(e) => setChosenForm(3)}>Investor</a>
              <a className={`bg-white font-inter-semibold text-dark fs-4 fw-bold px-4 py-2 rounded-3 button-shadow cursor-pointer ` + (chosenForm == 4 ? 'choosen-form-section-button' : null)} onClick={(e) => setChosenForm(4)}>Investor</a>
            </div>

            <form className='bg-white h-75 rounded-3 d-flex flex-wrap justify-content-evenly align-items-center contacts-form'>
                <div className='col-5 pt-4 border border-1 border-secondary rounded-2 position-relative'>
                  <p className='font-inter fs-5 px-2 contacts-form-title'>First Name</p>
                  <input type='text' className='w-100 fs-3 border-0 rounded-2' required />
                </div>

                <div className='col-5 pt-4 border border-1 border-secondary rounded-2 position-relative'>
                  <p className='font-inter fs-5 px-2 contacts-form-title'>Last Name</p>
                  <input type='text' className='w-100 fs-3 border-0 rounded-2' required />
                </div>

                <div className='col-5 pt-4 border border-1 border-secondary rounded-2 position-relative'>
                  <p className='font-inter fs-5 px-2 contacts-form-title'>Email</p>
                  <input type='email' className='w-100 fs-3 border-0 rounded-2' required />
                </div>

                <div className='col-5 pt-4 border border-1 border-secondary rounded-2 position-relative'>
                  <p className='font-inter fs-5 px-2 contacts-form-title'>Phone</p>
                  <input type='text' className='w-100 fs-3 border-0 rounded-2' required />
                </div>

                <div className='col-12 d-flex justify-content-center submit-form-button-container'>
                  <button className='fs-2 font-inter text-dark px-5 py-1 border-0 rounded-2 cursor-pointer overflow-hidden submit-form-button'>Submit</button>
                </div>
            </form>
          </div>
      </div>
    </div>
  )
}

export default ContactsPage