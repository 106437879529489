import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from './pages/HomePage';
import AboutServinPage from './pages/AboutServinPage';
import AboutUsPage from './pages/AboutUsPage';
import InvestorRelationsPage from './pages/InvestorRelationsPage';
import ContactsPage from './pages/ContactsPage';

import LoadingScreen from './components/LoadingScreen';
import Menu from './components/Menu';

function App() {
  return (
    <Router>
      <div className="App" style={{overflow: 'hidden'}}>
        <LoadingScreen />
        <Routes>
          <Route exact path='/' Component={HomePage} />
          <Route exact path='/about-servin' Component={AboutServinPage} />
          <Route exact path='/about-us' Component={AboutUsPage} />
          <Route exact path='/investor-relations' Component={InvestorRelationsPage} />
          <Route exact path='/contacts' Component={ContactsPage} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
