import React from 'react';
import gsap from 'gsap';

import '../static/footer.css';

import QrCode from '../assets/img/qr.png';
import InstagramIcon from '../assets/img/social-icons/social-instagram.png';
import LinkedInIcon from '../assets/img/social-icons/social-linkedin.png';
import TwitterIcon from '../assets/img/social-icons/social-twitter.png';
import GmailIcon from '../assets/img/social-icons/social-gmail.png';

const Footer = () => {

  return (
    <div className='container-fluid position-relative px-0 z-2 bg-cyan pt-5 d-flex flex-column justify-content-between align-items-center' style={{minHeight: '50vh'}} id='footer-section'>
        <div className='col-12 d-flex flex-column flex-md-row justify-content-evenly align-items-center row-gap-5'>
            <div className='col-9 col-sm-6 col-md-3'>
                <h1 className='logo-text text-center text-md-start text-white font-shanti'>SERVIN</h1>
                <div className='col-lg-10 col-xl-9 d-flex flex-row justify-content-between align-items-end'>
                    <div className='col-auto position-relative social-container'>
                        <img src={InstagramIcon} className='img-fluid cursor-pointer social-icon' onClick={(e) => {window.open("https://instagram.com", "_blank")}} />
                    </div>

                    <div className='col-auto position-relative social-container'>
                        <img src={LinkedInIcon} className='img-fluid cursor-pointer social-icon' onClick={(e) => {window.open("https://linkedin.com", "_blank")}} />
                    </div>

                    <div className='col-auto position-relative social-container'>
                        <img src={TwitterIcon} className='img-fluid cursor-pointer social-icon' onClick={(e) => {window.open("https://twitter.com", "_blank")}} />
                    </div>

                    <div className='col-auto position-relative social-container'>
                        <img src={GmailIcon} className='img-fluid cursor-pointer social-icon' onClick={(e) => {window.open("https://gmail.com", "_blank")}} />
                    </div>
                </div>

                <div className='mt-5 footer-hide-section-big-screen'>
                    <p className='responsive-text-2 text-color-dark fw-light'>Kurzova 2222/16, Stodůlky, 15500 Praha 5.</p>
                </div>
            </div>

            <div className='col-4 px-3 d-flex justify-content-center footer-hide-section-big-screen'>
                <div className='col-md-11 col-lg-9 col-xl-8'>
                    <img src={QrCode} className='img-fluid' />
                </div>
            </div>

            <div className='col-12 col-md-3 d-flex justify-content-center'>
                <div className='col-lg-8 col-xl-7'>
                    <h1 className='text-center text-md-start links-title-text fw-light fst-italic'>Links</h1>

                    <div className='d-flex flex-column align-items-center align-items-md-stretch'>
                        <a href='/about-us/' className='text-black fs-4 fw-light w-auto my-2 position-relative footer-link-button'>About Us</a>
                        <a href='/about-servin/' className='text-black fs-4 fw-light w-auto my-2 position-relative footer-link-button'>About Servin</a>
                        <a href='/investor-relations/' className='text-black fs-4 fw-light w-auto my-2 position-relative footer-link-button'>Investor relations</a>
                        <a href='/contacts/' className='text-black fs-4 fw-light w-auto my-2 position-relative footer-link-button'>Contact us</a>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-12 pt-4 mt-5 d-flex justify-content-center footer-lower-section'>
            <div className='col-12 col-md-4 mb-3 d-flex flex-column align-items-center'>
                <div className='my-1 footer-hide-section-small-screen'>
                    <p className='responsive-text-4 text-color-dark fw-light'>Kurzova 2222/16, Stodůlky, 15500 Praha 5.</p>
                </div>

                <p className='text-color-dark fw-light fs-5 m-0'>© 2024 Servin Tech s.r.o.</p>
                <p className='text-color-dark fw-light fs-5 m-0'>All rights reserved</p>
            </div>
        </div>
    </div>
  )
}

export default Footer